import CodeExample from "components/docs/code-examples/CodeExample";
import HeaderBar from "components/docs/layout/HeaderBar";
import CrossGuideLinks from "components/docs/navigation/CrossGuideLinks";
import LINKS from "components/docs/navigation/links";
import NavigationScrollTracker from "components/docs/navigation/NavigationScrollTracker";
import NavigationScrollTrackerWithAnchor from "components/docs/navigation/NavigationScrollTrackerWithAnchor";
import SmallWidthSection from "components/docs/sections/SmallWidthSection";
import UnevenTwoColumnCenteredSection from "components/docs/sections/UnevenTwoColumnCenteredSection";
import CodeExampleExplainer from "components/docs/shared-components/CodeExampleExplainer";
import DocsHelmet from "components/docs/shared-components/DocsHelmet";
import SectionDivider from "components/docs/shared-components/SectionDivider";
import { StaticFancyPageContainerWithTOC } from "components/docs/shared-components/StaticFancyPageContainer";
import { Link, PageProps } from "gatsby";
import React from "react";
import styled from "styled-components";

/**
 * Small width section with padding
 */
const Section = styled(SmallWidthSection).attrs({ className: "mt-9 mb-9" })``;

/**
 * Small width section with smaller padding above
 */
const ShortSection = styled(SmallWidthSection).attrs({ className: "mb-9" })`
  margin-top: 0px;
`;

// Used to reduce repeated code for the code examples
const WritesIntroCodeExample = ({ endpoint, fileName }: { endpoint: string; fileName: string }) => (
  <CodeExample
    folder="merge-writes/writes/intro"
    codeBlockName={
      <p>
        Example body of POST request to <code>{endpoint}</code>
      </p>
    }
    fileBaseName={fileName}
    style={{ maxHeight: "none" }}
    hasLineNumbers={false}
  />
);

/**
 * Creates an Introduction to Merge Writes guide
 */
const WritesIntro = ({ location }: PageProps<unknown, unknown, Record<string, unknown> | null>) => (
  <StaticFancyPageContainerWithTOC
    tableOfContents={[
      {
        text: "Introducing Writes",
        linkItems: [{ text: "Limitations" }],
      },
      {
        text: "POST Requests To Merge",
        linkItems: [
          { text: "Authorization" },
          {
            text: "Request Body",
            linkItems: [
              { text: "Model", anchorSlug: "model-to-merge" },
              { text: "Remote User ID (ATS only)" },
            ],
          },
        ],
      },
      {
        text: "Response to POST Request",
        linkItems: [
          { text: "Model", anchorSlug: "model-from-merge" },
          { text: "Warnings and Errors" },
        ],
      },
    ]}
  >
    <DocsHelmet
      title="Merge Writes: Introduction"
      description="Learn how to use Merge Writes in your product."
    />
    <SmallWidthSection>
      <NavigationScrollTracker>
        <HeaderBar
          title="Writes"
          subtitle="Make POST and PATCH requests to Merge to write data to third-party platforms"
        />
      </NavigationScrollTracker>
    </SmallWidthSection>

    <ShortSection>
      <NavigationScrollTrackerWithAnchor title="Introducing Writes" headingLevel="h3">
        <p>Merge’s Unified API allows you to write data to third-party platforms.</p>
        <p>
          You can <strong>create new entities</strong> and <strong>update existing entities</strong>{" "}
          in your end users’ Linked Accounts.
        </p>
      </NavigationScrollTrackerWithAnchor>
      <NavigationScrollTrackerWithAnchor headingLevel="h4" title="Limitations">
        <p>
          Merge supports <strong>POST requests</strong> and <strong>PATCH requests</strong> based on
          availability from third-party platforms.
        </p>
        <p>
          Refer to our <Link to={LINKS.API_REFERENCE.linkTo}>API Reference</Link> to confirm which
          platforms and data models support POST and PATCH requests. We are constantly increasing
          our support across third-party platforms.
        </p>
      </NavigationScrollTrackerWithAnchor>
    </ShortSection>
    <SectionDivider />

    <Section>
      <NavigationScrollTrackerWithAnchor title="POST Requests to Merge" headingLevel="h3">
        <p>A POST request to Merge’s API consists of two parts:</p>
        <ol>
          <li>Authorization</li>
          <li>Request Body</li>
        </ol>
      </NavigationScrollTrackerWithAnchor>
      <NavigationScrollTrackerWithAnchor title="Authorization" className="mt-9" headingLevel="h4">
        <p>
          As with any Merge API interaction, POST requests to Merge require authentication
          parameters to (1) authorize users to create or update data and (2) tell Merge which
          third-party platform to write to.
        </p>
        <p>To authorize your request:</p>
        <ol>
          <li>
            Add your <strong>Merge API key</strong> (from your{" "}
            <a href="https://app.merge.dev/keys"> dashboard</a>) to the request headers
          </li>
          <li>
            Add your user’s <code>account_token</code> to identify the third-party platform and
            linked account to update
          </li>
        </ol>
        <aside>
          Refer to our <Link to={LINKS.AUTHENTICATION.linkTo}>Authentication guide</Link> for more
          details on using <code>account_token</code>.
        </aside>
      </NavigationScrollTrackerWithAnchor>
      <NavigationScrollTrackerWithAnchor headingLevel="h4" title="Request Body">
        <p>Your POST request body will include the following:</p>
        <ol>
          <li>
            A <code>model</code> field that contains the object data to write to the third-party
            platform
          </li>
          <li>
            For ATS integrations: a <code>remote_user_id</code> parameter to confirm that you have
            permission to POST data to that integration
          </li>
        </ol>
      </NavigationScrollTrackerWithAnchor>
    </Section>

    <UnevenTwoColumnCenteredSection
      columnA={
        <>
          <NavigationScrollTrackerWithAnchor
            headingLevel="h5"
            title="Model"
            anchorSlug="model-to-merge"
          >
            <p>
              The <code>model</code> body parameter contains the field values of the Common Model
              instance you are looking to create.
            </p>
            <p>
              These fields expect <strong>different data types</strong> depending on the category,
              third-party platform, and Common Model.
            </p>
            <p>For example:</p>
            <ul>
              <li>
                For Merge HRIS, <code>Employee</code> emails are located in the{" "}
                <code>personal_email</code> and <code>work_email</code> fields as strings
              </li>
              <li>
                For Merge ATS, <code>Candidate</code> emails are located in the{" "}
                <code>email_addresses</code> field, which is an array of objects with two fields (
                <code>value</code> and <code>email_address_type</code>)
              </li>
            </ul>
            <aside>
              Refer to our <Link to={LINKS.API_REFERENCE.linkTo}>API Reference</Link> to learn more
              about specific model parameters.
            </aside>
          </NavigationScrollTrackerWithAnchor>
        </>
      }
      columnB={
        <>
          <CodeExampleExplainer>HRIS Example</CodeExampleExplainer>
          <WritesIntroCodeExample endpoint="/employees" fileName="hris-model" />
          <CodeExampleExplainer>ATS Example</CodeExampleExplainer>
          <WritesIntroCodeExample endpoint="/candidates" fileName="ats-model" />
        </>
      }
    />

    <UnevenTwoColumnCenteredSection
      columnA={
        <>
          <NavigationScrollTrackerWithAnchor headingLevel="h5" title="Remote User ID (ATS only)">
            <p>
              <strong>Some ATS integrations</strong> need to verify that your user has permission to
              create candidates and link them to open jobs.
            </p>
            <p>
              For these platforms, add <strong>Merge’s ID for your end user</strong> (specifically,
              the <code>id</code> field in the response from a GET request to Merge’s{" "}
              <code>/users</code> endpoint) in the <code>remote_user_id</code> field in the Request
              Body (as in the example).
            </p>
            <aside>
              To check if this parameter is required for a given ATS integration, use the{" "}
              <Link to={LINKS.LINKED_ACCOUNTS.linkTo}>Linked Accounts endpoint</Link>.
            </aside>
          </NavigationScrollTrackerWithAnchor>
        </>
      }
      columnB={
        <>
          <CodeExampleExplainer>ATS Example</CodeExampleExplainer>
          <WritesIntroCodeExample endpoint="/candidates" fileName="ats-remote-user-id" />
        </>
      }
    />

    <SectionDivider />

    <Section>
      <NavigationScrollTrackerWithAnchor headingLevel="h3" title="Response to POST Request">
        <p>
          After making a request to Merge’s POST endpoints, the response will be a JSON object with
          up to four properties:
        </p>
        <ol>
          <li>
            A <code>model</code> field containing the fields of the newly created common model, if
            successfully created
          </li>
          <li>
            A <code>warnings</code> field containing messaging on issues with the request that did
            not cause it to fail
          </li>
          <li>
            An <code>errors</code> field containing messaging on issues with the request that caused
            it to fail
          </li>
          <li>
            If Debug Mode is enabled, a <code>logs</code> field containing the IDs of logs related
            to outbound requests made to the third-party platform during the request
          </li>
        </ol>
      </NavigationScrollTrackerWithAnchor>
      <NavigationScrollTrackerWithAnchor
        headingLevel="h4"
        title="Model"
        anchorSlug="model-from-merge"
      >
        <p>
          If the POST request is successful, the <code>model</code> field will contain a full JSON
          representation of the newly created Common Model instance.
        </p>
        <p>
          The fields and associated values within the <code>model</code> object will largely
          correspond to what was sent in the POST request body, with the following exceptions:
        </p>
        <ol>
          <li>
            The Merge ID of the newly created model will be generated and contained in the{" "}
            <code>id</code> field
          </li>
          <li>
            Any <strong>Nested Writes</strong> will be resolved, and the Merge IDs of the newly
            created related Common Model instances will be returned in the response within their
            respective fields
          </li>
        </ol>
        <aside>
          Learn more about <strong>Nested Writes</strong> in our guide to{" "}
          <Link to={LINKS.MERGE_WRITES_RELATED_NESTED.linkTo}>Writing Nested Data</Link>
        </aside>
      </NavigationScrollTrackerWithAnchor>
      <NavigationScrollTrackerWithAnchor headingLevel="h4" title="Warnings and Errors">
        <p>
          The <code>warnings</code> field contains details on issues that don’t stop the request
          from being successful.
        </p>
        <p>
          The <code>errors</code> field contains details on issues that cause the request to fail.
        </p>
        <aside>
          Learn more about <strong>warnings and errors</strong> in our guide to{" "}
          <Link to={LINKS.MERGE_WRITES_TROUBLESHOOTING.linkTo}>Troubleshooting Writes</Link>
        </aside>
      </NavigationScrollTrackerWithAnchor>
    </Section>

    <Section>
      <CrossGuideLinks location={location} usesQuaternaryLinks />
    </Section>
  </StaticFancyPageContainerWithTOC>
);

export default WritesIntro;
